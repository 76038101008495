import React from "react";
import { TittleSection } from "../TittleSection";
import style from "../../assets/styles/section.module.css";
import logo_rofc from "../../assets/images/Home/rofc.png";
import logo_helena from "../../assets/images/Home/helena.jpg";
import logo_vien_khoa_hoc from "../../assets/images/Home/logoWASI-removebg.png";
import logo_htx from "../../assets/images/Home/htx-nss.png";
import { useTranslation } from "react-i18next";

interface CardInforProps {
  image: string;
  name: string;
  description: string;
  imageClassName?: string;
}
export const CoopSection = () => {
  const { t } = useTranslation();
  const CardInfor = ({
    image,
    name,
    description,
    imageClassName,
  }: CardInforProps) => {
    return (
      <div className="col-12 col-lg-3 col-md-6 px- grow">
        <div className="w-full flex justify-center items-center h-28">
          <img src={image} className={imageClassName} alt="" />
        </div>
        <p className="text-center font-bold my-6">{name}</p>
        <p className={style.cardInfoDesc}>{description}</p>
      </div>
    );
  };
  return (
    <div className={`${style.section} bg-[#F1F1EA]`}>
      <TittleSection title={t("home_section_partner_title")} />
      <div className="flex justify-center bootstrap-wrapper">
        <div className="">
          <div className="row">
            <CardInfor
              image={logo_vien_khoa_hoc}
              name={t("home_section_partner1_name")}
              description={t("home_section_parter1_desc")}
              imageClassName="h-full cardInfoImage"
            />
            <CardInfor
              image={logo_rofc}
              name={t("home_section_partner2_name")}
              description={t("home_section_parter2_desc")}
              imageClassName="h-full cardInfoImage"
            />
            <CardInfor
              image={logo_helena}
              name={t("home_section_partner3_name")}
              description={t("home_section_parter3_desc")}
              imageClassName="h-full cardInfoImage"
            />
            <CardInfor
              image={logo_htx}
              name={t("home_section_partner4_name")}
              description={t("home_section_parter4_desc")}
              imageClassName="h-full cardInfoImage"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
