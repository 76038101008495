import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setTabsDevice } from "../../redux/layoutSlice";
import { Carousel } from "../../components/Carousel";
import USP from "../../components/USP/index";
import bannerF_1 from "../../assets/images/DeviceSection/enfarmF/1 vie.jpg";
import bannerF_2 from "../../assets/images/DeviceSection/enfarmF/2 vie.jpg";
import bannerF_3 from "../../assets/images/DeviceSection/enfarmF/3 vie.jpg";
import bannerF_4 from "../../assets/images/DeviceSection/enfarmF/4 vie.jpg";
import bannerF_1_en from "../../assets/images/DeviceSection/enfarmF/1 eng.jpg";
import bannerF_2_en from "../../assets/images/DeviceSection/enfarmF/2 eng.jpg";
import bannerF_3_en from "../../assets/images/DeviceSection/enfarmF/3 eng.jpg";
import bannerF_4_en from "../../assets/images/DeviceSection/enfarmF/4 eng.jpg";
import bannerFPlus_1 from "../../assets/images/DeviceSection/enfarmFPlus/1 vie.jpg";
import bannerFPlus_2 from "../../assets/images/DeviceSection/enfarmFPlus/2 vie.jpg";
import bannerFPlus_3 from "../../assets/images/DeviceSection/enfarmFPlus/3 vie.jpg";
import bannerFPlus_4 from "../../assets/images/DeviceSection/enfarmFPlus/4 vie.jpg";
import bannerFPlus_1_en from "../../assets/images/DeviceSection/enfarmFPlus/1 eng.jpg";
import bannerFPlus_2_en from "../../assets/images/DeviceSection/enfarmFPlus/2 eng.jpg";
import bannerFPlus_3_en from "../../assets/images/DeviceSection/enfarmFPlus/3 eng.jpg";
import bannerFPlus_4_en from "../../assets/images/DeviceSection/enfarmFPlus/4 eng.jpg";
import styleCarousel from "../../assets/styles/carousel.module.css";
import style from "./styles.module.css";

const FSlides = [bannerF_1, bannerF_2, bannerF_3, bannerF_4];

const FSlidesEn = [bannerF_1_en, bannerF_2_en, bannerF_3_en, bannerF_4_en];

const FPlusSlides = [
  bannerFPlus_1,
  bannerFPlus_2,
  bannerFPlus_3,
  bannerFPlus_4,
];

const FPlusSlidesEn = [
  bannerFPlus_1_en,
  bannerFPlus_2_en,
  bannerFPlus_3_en,
  bannerFPlus_4_en,
];

const autoPlaySpeed = 7000;
const styleLeftArrow = {
  top: "45%",
  right: "95%",
  zIndex: 120,
};
const styleRightArrow = {
  top: "45%",
  left: "95%",
  zIndex: 120,
};

const DeviceSection = () => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const FSlidesByLanguage = language === "en" ? FSlidesEn : FSlides;
  const FPlusSlidesByLanguage = language === "en" ? FPlusSlidesEn : FPlusSlides;

  let dispatch = useDispatch();
  const tabsDevice = useSelector((state: any) => state.layoutSlice.tabsDevice);

  return (
    <div className={style.section}>
      <div className={style.navigation}>
        <button
          onClick={() => dispatch(setTabsDevice(0))}
          className={`${style.tab} ${tabsDevice === 0 ? style.active : ""}`}
        >
          {t("device_1_section_tab_name")}
        </button>
        <button
          onClick={() => dispatch(setTabsDevice(1))}
          className={`${style.tab} ${tabsDevice === 1 ? style.active : ""}`}
        >
          {t("device_2_section_tab_name")}
        </button>
      </div>
      {tabsDevice === 0 && (
        <div>
          <div className="w-full flex xl:flex-col gap-8">
            <div className="w-1/2 xl:w-full">
              <Carousel
                styleLeftArrow={styleLeftArrow}
                styleRightArrow={styleRightArrow}
                slidesToShow={1}
                slidesToScroll={1}
                autoplaySpeed={autoPlaySpeed}
                showArrows={true}
              >
                {FSlidesByLanguage.map((img, idx) => (
                  <div
                    key={`slide_${idx}`}
                    className="px-4 w-full rounded-[32px]"
                  >
                    <div className={styleCarousel.minipost_carousel}>
                      <img
                        src={img}
                        alt={`slide_${idx}`}
                        className="object-cover object-center w-full"
                      />
                    </div>
                  </div>
                ))}
              </Carousel>
            </div>
            <div className="w-1/2 xl:w-full flex flex-col gap-2">
              <h2>{t("device_1_section_prod_name")}</h2>
              <p>{t("device_1_section_prod_desc")}</p>
              <div className="flex flex-col justify-center gap-9">
                <USP content={t("device_1_section_prod_usp_1")} />
                <USP content={t("device_1_section_prod_usp_2")} />
                <USP content={t("device_1_section_prod_usp_3")} />
              </div>
            </div>
          </div>
          <div className={style.highlightContainer}>
            <div className={style.highlightTitle}>{t("highlight.title")}</div>
            <div className={style.highlightSubtitle}>
              {t("highlight.subtitle")}
            </div>
          </div>
          <div className="w-full flex justify-center sm:mb-5 mt-6">
            <button
              className={style.order_button}
              onClick={() => {
                window.open(
                  "https://forms.gle/Aj6zXjjynMsmiCbdA"
                );
              }}
            >
              {t("order")}
            </button>
          </div>
        </div>
      )}
      {tabsDevice === 1 && (
        <div>
          <div className="w-full flex xl:flex-col gap-8">
            <div className="w-1/2 xl:w-full">
              <Carousel
                styleLeftArrow={styleLeftArrow}
                styleRightArrow={styleRightArrow}
                slidesToShow={1}
                slidesToScroll={1}
                autoplaySpeed={autoPlaySpeed}
                showArrows={true}
              >
                {FPlusSlidesByLanguage.map((img, idx) => (
                  <div
                    key={`slide_${idx}`}
                    className="px-4 w-full rounded-[32px]"
                  >
                    <div className={styleCarousel.minipost_carousel}>
                      <img
                        src={img}
                        alt={`slide_${idx}`}
                        className="object-cover object-center w-full"
                      />
                    </div>
                  </div>
                ))}
              </Carousel>
            </div>
            <div className="w-1/2 xl:w-full flex flex-col gap-2">
              <h2>{t("device_2_section_prod_name")}</h2>
              <p>{t("device_2_section_prod_desc")}</p>
              <div className="flex flex-col justify-center gap-9">
                <USP content={t("device_2_section_prod_usp_1")} />
                <USP content={t("device_2_section_prod_usp_2")} />
                <USP content={t("device_2_section_prod_usp_3")} />
                <USP content={t("device_2_section_prod_usp_4")} />
              </div>
            </div>
          </div>
          <div className={style.highlightContainer}>
            <div className={style.highlightTitle}>{t("highlight.title")}</div>
            <div className={style.highlightSubtitle}>
              {t("highlight.subtitle")}
            </div>
          </div>
          <div className="w-full flex justify-center sm:mb-5 mt-6">
            <button
              className={style.order_button}
              onClick={() => {
                window.open(
                  "https://forms.gle/Aj6zXjjynMsmiCbdA"
                );
              }}
            >
              {t("order")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DeviceSection;
