import style from "../assets/styles/home.module.css";
import styleCarousel from "../assets/styles/carousel.module.css";
import bg_cafe from "../assets/images/Home/bg_cafe_small.png";
// import gg_play from "../assets/images/img/gg_play.png";
// import app_store from "../assets/images/img/app_store.png";
// import gg_play_en from "../assets/images/img/gg_play_en.png";
// import app_store_en from "../assets/images/img/app_store_en.png";
// import hand from "../assets/images/Home/image.png";
// import enfarm_logo from "../assets/images/img/enFarm_logo_web.png";
import shapewave from "../assets/images/Home/shapewave.png";
import AnimatedNumber from "react-animated-number";
// import coffee_illustration from "../assets/images/Home/coffee-illustration.png";
import { Carousel } from "../components/Carousel";
import { FeatureSection } from "../layouts/HomeSection/FeatureSection";
import { ProductionSection } from "../layouts/HomeSection/ProductionSection";
import { NewsSection } from "../layouts/HomeSection/NewsSection";
import { KnowledgeSection } from "../layouts/HomeSection/KnowledgeSection";
import { CoopSection } from "../layouts/HomeSection/CoopSection";
import { StorySection } from "../layouts/HomeSection/StorySection";
import { DownloadSection } from "../layouts/HomeSection/DownloadSection";
import CertSection from "../layouts/CertSection/Home";
import { ProgressiveImg } from "../components/ProgressiveImg";
import { useTranslation } from "react-i18next";
// import { Knowledge_ } from "./Knowledge_";

export const Home = () => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  return (
    <div className={style.container}>
      <div className={style.wrapper_bg}>
        <nav className={style.wrapper}>
          <div className="w-full flex flex-row gap-[52px] sm:flex-col mb-10">
            <div className="w-2/3 sm:w-full">
              <h1 className={style.big_title}>
                enfarm <br /> {t("home_banner_title")}
              </h1>
              <article>{t("home_banner_content")}</article>
              {/* <div className="w-full flex gap-4 mt-4">
                <img loading="lazy" src={language === 'en' ? gg_play_en : gg_play} alt="" className="w-1/3" />
                <img loading="lazy" src={language === 'en' ? app_store_en : app_store} alt="" className="w-1/3" />
              </div> */}
            </div>
            <div className="w-1/3 md:hidden relative flex justify-center items-center">
              <div
                className={`${style.elipse_medium} absolute top-0 right-0`}
              ></div>
              <div
                className={`${style.elipse_big} absolute bottom-0 left-0`}
              ></div>
              <div
                className={`${style.elipse_small} absolute bottom-[90px] left-[22px]`}
              ></div>
              <div className={style.img_decor}>
                <ProgressiveImg
                  hash="LDF5+}.4Fd4:lR~WD*I:_L9GRnD%"
                  src={bg_cafe}
                  className="object-cover object-center aspect-square"
                />
              </div>
            </div>
          </div>
          <Carousel autoplaySpeed={1000000000}>
            <div className="p-4">
              <div className={styleCarousel.pr_carousel}>
                <div id = 'before'>
                  <p>
                    {t("home_before_banner_carousel_title_1.1")}
                    <span className={styleCarousel.percentage}>
                      {'~'}
                      <AnimatedNumber
                        initialValue="0"
                        value={parseInt(t("home_before_banner_carousel_title_1.2"))}
                        stepPrecision={0}
                        duration={1500}
                        style={{
                          transition: '0.8s ease-out',
                          fontSize: 32,
                          fontWeight: "bold",
                          fontStyle: "italic"
                        }}
                      />
                      {'%'}
                    </span>
                    {t("home_before_banner_carousel_title_1.3")}
                  </p>
                </div>                

                <h3>{t("home_banner_carousel_title_1")}</h3>
                <p>{t("home_banner_carousel_content_1")}</p>
              </div>
            </div>
            <div className="p-4">
              <div className={styleCarousel.pr_carousel}>
              <div id = 'before'>
                  <p>
                    {t("home_before_banner_carousel_title_2.1")}
                    <span className={styleCarousel.percentage}>
                      <AnimatedNumber
                        initialValue="0"
                        value={parseInt(t("home_before_banner_carousel_title_2.2"))}
                        stepPrecision={0}
                        duration={1500}
                        style={{
                          transition: '0.8s ease-out',
                          fontSize: 32,
                          fontWeight: "bold",
                          fontStyle: "italic"
                        }}
                      />
                      {'%'}
                    </span>
                    {t("home_before_banner_carousel_title_2.3")}
                  </p>
                </div>
                <h3 dangerouslySetInnerHTML={
                  {__html: t('home_banner_carousel_title_2', {interpolation: {escapeValue: false}})}
                } />
                <p>{t("home_banner_carousel_content_2")}</p>
              </div>
            </div>
            <div className="p-4">
              <div className={styleCarousel.pr_carousel}>
              <div id = 'before'>
                  <p>
                    {t("home_before_banner_carousel_title_3.1")}
                    {/* <span className={styleCarousel.percentage}>
                      <AnimatedNumber
                        initialValue="0"
                        value={parseInt(t("home_before_banner_carousel_title_3.2"))}
                        stepPrecision={0}
                        duration={1500}
                        style={{
                          transition: '0.8s ease-out',
                          fontSize: 32,
                          fontWeight: "bold",
                          fontStyle: "italic"
                        }}
                      />
                    </span> */}
                    {t("home_before_banner_carousel_title_3.3")}
                  </p>
                </div>
                <h3 dangerouslySetInnerHTML={
                  {__html: t('home_banner_carousel_title_3', {interpolation: {escapeValue: false}})}
                } />
                <p>{t("home_banner_carousel_content_3")}</p>
              </div>
            </div>
          </Carousel>
        </nav>
      </div>
      <div className="w-full">
        <img src={shapewave} alt="" className="w-full" />
      </div>

      <main>
        <ProductionSection />
        <FeatureSection />
        <NewsSection />
        <KnowledgeSection />
        <StorySection />
        <CoopSection />
        {/* <Knowledge_ /> */}
        <CertSection/>
        <DownloadSection />
      </main>
    </div>
  );
};
